<template>
  <b-row class="hp-authentication-page">
    <left-item />

    <b-col cols="12" lg="6" class="py-sm-64 py-lg-0">
      <b-row align-v="center" align-h="center" class="h-100 mx-4 mx-sm-n32">
        <b-col
          cols="12"
          md="9"
          xl="7"
          class="col-xxxl-5 px-8 px-sm-0 pt-24 pb-48"
        >
          <h1 class="mb-0 mb-sm-24">登录</h1>
          <p class="mt-sm-8 mt-sm-0 text-black-60">
            欢迎使用后台管理系统
          </p>

          <b-form class="mt-16 mt-sm-32 mb-8">
            <b-form-group
              label="邮箱 :"
              label-for="loginUsername"
              class="mb-16"
            >
              <b-form-input id="loginUsername" type="text" v-model="email"></b-form-input>
            </b-form-group>

            <b-form-group
              label="密码 :"
              label-for="loginPassword"
              class="mb-16"
            >
              <b-form-input id="loginPassword" type="password" v-model="password"></b-form-input>
            </b-form-group>

            <el-button :plain="true" @click="loginaajx" type="primary" class="w-100" :loading="loading">登 录</el-button>
          </b-form>


          <footer-item />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";

import LeftItem from "../LeftItem.vue";
import FooterItem from '../FooterItem.vue';

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    LeftItem,
    FooterItem,
  },
  data(){
    return{
      email:"",
      password:"",
      loading:false,
    }
  },
  methods:{
    async loginaajx(){
      if(!this.email){
        this.$message.error('请输入邮箱');
        return;
      }
      if(!this.password){
        this.$message.error('请输入邮箱');
        return;
      }

      this.loading = true;
      var ret = await this.$axios.post('/user_api/login',{user:this.email,pass:this.password});
      this.loading = false;
      if(ret){
        if(ret.code == 1){
          var user = ret.user;
          for (const key in user) {
            for (const key2 in this.$store.state.user) {
              if(key == key2){
                this.$store.state.user[key2] = user[key];
              }
            }
          }
          window.localStorage.setItem('token',ret.user.token);
          this.$router.replace('/');
        }else{
          this.$message.error(ret.msg);
        }
      }
    }
  }
};
</script>
